/* eslint-disable prettier/prettier */
import Vue from "vue";

Vue.filter("time12hour", (value: any) => {
    ///debugger;
    if (!value) return "";
     let time = value.split(":");
     let hour = parseInt(time[0]);
    //let hour = value.hours;
     let minute = parseInt(time[1]);
    //let minute = value.minutes;
    let format = hour >= 12 ? "PM" : "AM";
    let currentTime = hour > 12 ? hour - 12 : hour;
    return ("0" + currentTime).slice(-2) + ":" + ("0" + minute).slice(-2) + " " + format;
});
