/* eslint-disable prettier/prettier */
import CryptoJS from "crypto-js";
const secritKey: string = "#&&&jMNDAmmm%%*_(())@@hh2323";

interface AuthUser {
    id: number;
    firstName: string;
    lastName: string;
    token: string;
    email: string;
    phone:string;
    imageUrl: string;
    role: number;
    isDoctorRequest: boolean;
}

export default {
    getAuthUser(): AuthUser {
        return JSON.parse(this.decryptLocalStorage()) || {};
    },

    getFullName() {
        let userData = JSON.parse(this.decryptLocalStorage());
        return (({ firstName: userData.firstName, lastName: userData.lastName } as any) || "");
    },

    getAddress(): string {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.address || "";
    },

    setToken(userInfo: any): void {
        let encryptData = CryptoJS.AES.encrypt(
            JSON.stringify(userInfo),
            secritKey
        ) as CryptoJS.WordArray;
      localStorage.setItem("token", JSON.stringify(userInfo));
    },

    getToken(): string {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.token || "";
    },

    getIsDoctorRequest(): boolean {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.isDoctorRequest || false;
    },

    getUserName(): string {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.email || "";
    },

    getImageUrl(): string {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.imageUrl || "";
    },

    getRole(): number {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.role || 0;
    },

    getId(): number {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.id || 0;
    },
    IsEmailLogin(): boolean {
        let user = JSON.parse(this.decryptLocalStorage());
        return user.isEmailLogin || false;
    },

    isLoggednIn(): boolean { 
        return localStorage.getItem("token") !== null;
    },

    logout(): void {
        localStorage.removeItem("token");
        localStorage.clear();
    },

    decryptLocalStorage(): string {
        if (this.isLoggednIn()) {
            //return CryptoJS.AES.decrypt(
            //    localStorage.getItem("token") || "{}",
            //    secritKey
            //).toString(CryptoJS.enc.Utf8);
          return localStorage.getItem("token")||"{}";
        }
        return "{}";
    }
};
