import { Router } from "@/helper/RouteModel";

const routes: Router[] = [
  {
    path: "/donor",
    component: () => import("@/blood-donor-app/views/Layout.vue"),
    children: [
      {
        path: "",
        name: "Donor Home",
        component: () => import("@/blood-donor-app/views/Home.vue")
      }
    ]
  }
];

export default routes;
