import { Validator } from "vee-validate";

Validator.extend("isAfter", {
  validate: (value: string | number | Date, { compare }: any) => {
    let rhs = new Date(value);
    let lhs = new Date(compare);
    return rhs.getTime() >= lhs.getTime();
  },
  paramNames: ["compare"],
  getMessage(field, val) {
    return `${field} grater than Start Date`;
  }
});

Validator.extend("beforeCurrentDate", {
  getMessage(field, val) {
    return `${field} Must be less than Current Date`;
  },
  validate(value, field) {
    let start = new Date(value);
    let end = new Date();
    return end > start;
  }
});

Validator.extend("afterCurrentDate", {
  getMessage(field, val) {
    return `${field} Must be grater than Current Date`;
  },
  validate(value, field) {
    let start = new Date(value);
    let end = new Date();
    return start > end;
  }
});
