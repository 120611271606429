import { Router } from "@/helper/RouteModel";

const routes: Router[] = [
  {
    path: "/diagnostic",
    component: () => import("@/diagnostic-app/views/Layout.vue"),
    children: [
      {
        path: "dashboard",
        component: () => import("@/diagnostic-app/views/Dashboard.vue"),
        children: [
          {
            path: "",
            redirect: "",
            component: () => import("@/diagnostic-app/views/pages/HospitalProfile.vue")
          },
          {
            path: "appointment-calendar",
            name: "hospital-appointment-calendar",
            component: () => import("@/diagnostic-app/views/pages/AppointmentCalendar.vue") // authorized
          },
          {
            path: "appointment-status",
            name: "hospital-appointment-status",
            component: () => import("@/diagnostic-app/views/pages/AppointmentStatus.vue") // authorized
          },
          {
            path: "add-service",
            name: "add-service",
            component: () => import("@/diagnostic-app/views/pages/AddServices.vue") // authorized
          },
          {
            path: "service",
            name: "service",
            component: () => import("@/diagnostic-app/views/pages/ServiceTimeSetting.vue") // authorized
          }
        ]
      },
      {
        path: "create",
        name: "Add Diagnostic",
        component: () => import("@/diagnostic-app/views/Create.vue")
      },
      {
        path: "",
        name: "Diagnostic center list",
        component: () => import("@/diagnostic-app/views/List.vue")
      },
      {
        path: "diagnostic-detail/:id",
        name: "diagnostic-detail",
        component: () => import("@/diagnostic-app/views/Details.vue")
      },
      {
        path: "createTest",
        name: "createTest",
        component: () => import("@/diagnostic-app/views/CreateTest.vue")
      }
    ]
  }
];

export default routes;
