import Vue from "vue";
import Router from "vue-router";
import DoctorRoute from "@/doctor-app/routers/Router";
import WebsiteRoute from "@/website/routers/Router";
import CommonRoute from "@/common-app/routers/Router";
import AdminRoute from "@/admin-app/routers/Router";
import DonorRoute from "@/blood-donor-app/routers/Router";
import DiagnosticRoute from "@/diagnostic-app/routers/Router";

Vue.use(Router);

var allRoutes: any = [];

allRoutes = allRoutes.concat(
  WebsiteRoute,
  CommonRoute,
  DoctorRoute,
  AdminRoute,
  DonorRoute,
  DiagnosticRoute
);

const mainrouter = allRoutes;

export default new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: mainrouter
});
