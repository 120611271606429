import axios from "axios";
import AuthUser from "./AuthUser";
import { ApiURIs } from "../global-config/ApiList";
export const AuthAxios = axios.create({
  baseURL: ApiURIs.baseUrl,
  headers: {
    "Content-Type": "application/json"
  }
});

AuthAxios.interceptors.request.use(
  config => {
    const token = AuthUser.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      //console.log("There is not token yet...");
      return config;
    }
  },
  err => {
    return Promise.reject(err);
  }
);

// Add a response interceptor
AuthAxios.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export const AuthAxiosFromData = axios.create({
  baseURL: ApiURIs.baseUrl
});

AuthAxiosFromData.interceptors.request.use(
  config => {
    const token = AuthUser.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      //console.log("There is not token yet...");
      return config;
    }
  },
  err => {
    return Promise.reject(err);
  }
);
export const UnAuthAxios = axios.create({
  baseURL: ApiURIs.baseUrl,
  headers: { "Content-Type": "application/json" }
});

// Add a response interceptor
UnAuthAxios.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Do something with response error
    return Promise.reject(error);
  }
);
