







































import { Vue, Component, Prop } from "vue-property-decorator";
import AlertService from "../service/AlertService";
import { Subscription } from "rxjs";
import Message from "../models/Message.vue";

@Component
export default class Alert extends Vue {
  public messages: any[] = [];
  public alertType: boolean = false;
  public subscription: Subscription;
  public showAlert = false;

  mounted() {
    this.subscription = AlertService.getMessage().subscribe((message: any) => {
      // console.log(message);
      this.subscription = AlertService.getMessage().subscribe((message: any) => {
        // console.log(message.text.message);
        if (typeof message.text === "string") {
          // console.log("string message");
          this.messages.push(message.text || {});
          this.showAlert = true;
          setTimeout(() => {
            this.messages = [];
            this.showAlert = false;
          }, 5000);
        } else if (message.text.status) {
          this.messages.push(message.text.message || {});
          this.alertType = true;
          this.showAlert = true;

          setTimeout(() => {
            this.messages = [];
            this.showAlert = false;
          }, 5000);
        } else {
          //this.messages.push("Error:" + message.text.message || {});
          this.messages.push(message.text.message || {});
          this.alertType = false;
          this.showAlert = true;
          setTimeout(() => {
            this.messages = [];
            this.showAlert = false;
          }, 5000);
        }
      });
    });
  }

  beforeDestroy() {
    this.subscription.unsubscribe();
    this.messages = [];
  }
}
