import { GetterTree, MutationTree, ActionTree, ActionContext } from "vuex";

interface State {
  userId: number;
  verifycode: string;
  isDoctorRequest: boolean;
}

type UserGetter = GetterTree<State, any>;

const state: State = {
  userId: 0,
  verifycode: "",
  isDoctorRequest: false
} as State;

const getters: UserGetter = {
  userId: state => state.userId,
  verifycode: state => state.verifycode,
  isDoctorRequest: state => state.isDoctorRequest
};

const mutations: MutationTree<State> = {
  addVerify(state, newUser: State) {
    state.userId = newUser.userId;
    state.verifycode = newUser.verifycode;
  },

  addDoctorRequest(state, isRequest: boolean) {
    state.isDoctorRequest = isRequest;
  }
};

const actions: ActionTree<State, any> = {
  addVerify(store: ActionContext<State, any>, newUser: State) {
    store.commit("addVerify", newUser);
  },
  addDoctorRequest(store: ActionContext<State, any>, isRequest: boolean) {
    store.commit("addDoctorRequest", isRequest);
  }
};

export const websiteModule = {
  state,
  getters,
  mutations,
  actions
};
