import { Router } from "@/helper/RouteModel";

const routes: Router[] = [
  {
    path: "/admin",
    component: () => import("@/admin-app/views/AdminLayout.vue")
  },
  {
    path: "/admin/chat-dashboard",
    name: "Chat Dashboard",
    component: () => import("@/admin-app/views/ChatBoard.vue")
  },
  {
    path: "/admin-dashboard",
    name: "admin-dashboard",
    component: () => import("@/admin-app/views/AdminLayoutTest.vue")
  },
  
];

export default routes;
