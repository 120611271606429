









































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import * as signalR from "@aspnet/signalr";
import AuthUser from "../../global-config/AuthUser";
import { ApiURIs } from "../../global-config/ApiList";
import ChatService from "../service/ChatService";
import AlertService from "../service/AlertService";

//import ChatComp from "@/components/Chat.vue"; // @ is an alias to /src
@Component
export default class Chat extends Vue {
  public openChatWindow: boolean = false;
  public select_service: boolean = false;
  public serviceSelected: boolean = false;
  public showHeaderTitle: boolean = true;
  public agentFound: boolean = false;
  public isAgent: boolean = false;
  public rateChatService: boolean = false;
  public thankYou: boolean = false;
  public minimized: boolean = false;
  public OwnUser: string = "";
  public listMessage: any = [];
  public messageText = "";
  public EmailText = "";
  public FullName = "";
  public connectionId: string = "";
  public ToConnectionId: string = "";
  public ToUsername: string = "";
  public AgentConnectionIdList: any = [];
  public rating: number = 0;
  public AgentOrCustomerMessage: string = "Waiting for Chat!";
  public IsTyping: boolean = true;
  public TypingIndicator: string = "Pls wait one of our agent will contact with you..";
  public connection = new signalR.HubConnectionBuilder().withUrl(ApiURIs.baseUrl + "/chat").build();
  onSubmit() {
    // console.log("On Submit Call" + AuthUser.getRole() + "|" + AuthUser.isLoggednIn());
    if (AuthUser.isLoggednIn() && AuthUser.getRole() == 3) {
      if (this.EmailText != AuthUser.getUserName()) {
        alert(" This email not a Agent, contact with admin");
        AlertService.sendMessage("Please use your agent email");
        return;
      }
    }
    this.OwnUser = this.EmailText;
    this.serviceSelected = true;
    this.select_service = true;
    this.showHeaderTitle = false;
    this.agentFound = true;
    this.AgentOrCustomerMessage = "Hi," + this.FullName;
    this.connect();
  }
  OpenChatWindow_fun() {
    if (this.openChatWindow) {
      this.openChatWindow = false;
    } else {
      this.openChatWindow = true;
    }
    if (AuthUser.getRole() == 3) {
      this.FullName = AuthUser.getFullName().firstName + " " + AuthUser.getFullName().lastName;
      this.EmailText = AuthUser.getUserName();
      this.email = AuthUser.getUserName();
      this.isAgent = true;
      this.AgentOrCustomerMessage = "Hi," + this.FullName;
      this.TypingIndicator = "Please wait for a customer!";
    } else {
      this.AgentOrCustomerMessage = "Hi," + this.FullName;
    }
  }
  connect() {
    // console.log("Connect Calling..");
    var role = 0;
    if (AuthUser.isLoggednIn()) {
      role = AuthUser.getRole();
    }
    // this.connection = new signalR.HubConnectionBuilder()
    //   .withUrl(
    //     ApiURIs.chatURL +
    //       "chat?username=" +
    //       this.EmailText +
    //       "&&role=" +
    //       role +
    //       ""
    //   )
    //   .configureLogging(signalR.LogLevel.Information)
    //   .build();
    if (this.connection.state != signalR.HubConnectionState.Connected) {
      this.connection
        .start()
        .then(() => this.connection.invoke("GetConnectionId"))
        .then((res) => {
          this.connectionId = res;
          // console.log("Connection Id:" + this.connectionId);
          if (AuthUser.getRole() != 3) {
            // console.log(this.EmailText);
            if (this.EmailText != "") {
              this.connection.invoke("SendMessage", this.EmailText, role, "Hi, I am " + this.FullName);
            }
          } else {
            // console.log(this.EmailText);
            if (this.EmailText != "") {
              this.connection.invoke("SendMessage", this.EmailText, role, "Hi,How can i help you?");
            }
          }
        });
    }
    this.connection.serverTimeoutInMilliseconds = 120000; // 1 second
    // this.connection.keepAliveIntervalInMilliseconds = 120000;
  }

  SendMessage() {
    if (this.messageText == "") {
      return false;
    }
    if (AuthUser.getRole() == 3 && this.ToConnectionId == "") {
      return false;
    }
    const insertdata = {
      fromUsername: this.EmailText,
      fromConnectionId: this.connectionId,
      message: this.messageText,
      IsOwnMessage: true,
    };
    this.listMessage.push(insertdata);
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      if (this.ToConnectionId != "") {
        this.connection.invoke("SendOneToOneMessage", this.EmailText, this.ToUsername, this.ToConnectionId, this.messageText);
      }
    } else {
      this.connection
        .start()
        .then(() =>
          this.connection.invoke("SendOneToOneMessage", this.EmailText, this.ToUsername, this.ToConnectionId, this.messageText)
        );
    }
    this.agentFound = true;
    this.messageText = "";
  }
  OnTyping() {
    var html = "Customer is typing...";
    if (AuthUser.getRole() != 3) {
      html = "Customer is typing...";
    } else {
      html = "BDDOT Agent is typing...";
    }
    this.connection.invoke("IsTyping", this.EmailText, this.ToUsername, this.ToConnectionId, html);
  }
  stopTyping() {
    var html = "";
    this.connection.invoke("IsTyping", this.EmailText, this.ToUsername, this.ToConnectionId, html);
  }
  ChatEnd() {
    this.connection.invoke("ChatEnd", this.EmailText, this.ToUsername, this.ToConnectionId, this.FullName);
    if (AuthUser.getRole() == 3) {
      //this.listMessage = [];
      this.ToConnectionId = "";
    } else {
      this.rateChatService = true;
      this.thankYou = false;
    }

    // AlertService.sendMessage("With " + this.ToUsername + " chat End");
  }
  getRating(e: number) {
    // console.log("getRating" + e);
    this.rating = e;
  }
  SubmitRating() {
    var data = {
      CustomerName: this.FullName,
      CustomerEmail: this.EmailText,
      ConnectionId: this.ToConnectionId,
      Rating: this.rating,
      AgentName: "",
      AgentUserName: this.ToUsername,
      Remarks: "",
    };
    ChatService.ChatRating(data).then((e) => {
      // console.log(e);
      this.thankYou = true;
      location.reload();
    });
  }
  get message() {
    return this.messageText;
  }
  set message(message) {
    this.messageText = message;
  }
  get email() {
    return this.EmailText;
  }
  set email(email) {
    this.EmailText = email;
  }
  get customerName() {
    return this.FullName;
  }
  set customerName(name) {
    this.FullName = name;
  }
  get Rating() {
    return this.rating;
  }
  set Rating(rating) {
    this.rating = rating;
  }
  playAudio() {
    let audio = new Audio("../../assets/audio/chat-sound.MP3");
    // audio.src = "../../assets/audio/chat-sound.MP3";
    // audio.load();
    // console.log(audio);
    audio.play();
  }
  mounted() {
    this.AgentOrCustomerMessage = this.FullName;
    if (AuthUser.getRole() == 3) {
      this.FullName = AuthUser.getFullName().firstName + " " + AuthUser.getFullName().lastName;
      this.EmailText = AuthUser.getUserName();
      this.email = AuthUser.getUserName();
      this.isAgent = true;
      this.AgentOrCustomerMessage = "Hi," + this.FullName;
      this.TypingIndicator = "Please wait for a customer!";
    } else {
      this.AgentOrCustomerMessage = "Hi," + this.FullName;
    }

    this.connection.on("ReceiveMessage", (fromUsername: string, fromConnectionId: string, data: string) => {
      var IsOwnMessage = false;
      // console.log(fromConnectionId + "|" + data);
      this.ToConnectionId = fromConnectionId;
      this.ToUsername = fromUsername;
      if (AuthUser.getRole() == 3) {
        this.FullName = AuthUser.getFullName().firstName + " " + AuthUser.getFullName().lastName;
        this.EmailText = AuthUser.getUserName();
      }
      if (this.connectionId == fromConnectionId) IsOwnMessage = true;
      const insertdata = {
        fromUsername: fromUsername,
        fromConnectionId: fromConnectionId,
        message: data,
        IsOwnMessage: IsOwnMessage,
      };
      this.listMessage.push(insertdata);
      // tslint:disable-next-line:no-console
      // console.log(this.listMessage);
      // tslint:disable-next-line:no-console
      // console.log(fromConnectionId, data);

      this.playAudio();
      // console.log("audioi");
    });
    this.connection.on("ChatEnd", (fromUsername: string, fromConnectionId: string, data: string) => {
      // console.log("Customer End the chat" + data);
      if (AuthUser.getRole() != 3) {
        this.rateChatService = true;
        this.thankYou = false;
      } else {
        AlertService.sendMessage(data + "Customer end the chat!!");
        // alert("Customer end the chat!!");
        //   this.listMessage = [];
        this.ToConnectionId = "";
        const insertdata = {
          fromUsername: fromUsername,
          fromConnectionId: fromConnectionId,
          message: data + " ended the chat",
          IsOwnMessage: false,
        };
        this.listMessage.push(insertdata);
      }
    });
    this.connection.on("SayWhoIsTyping", (fromUsername: string, fromConnectionId: string, data: string) => {
      if (data != "") {
        this.IsTyping = true;
      } else {
        this.IsTyping = false;
      }
      this.TypingIndicator = data;
      // console.log(fromUsername + "" + data);
    });
    this.connection.stop().then((e) => {
      // console.log("stop");
      // console.log(e);
      //this.connect();
    });
    this.connection.onclose(() => {
      // console.log("closing call..");
      this.connect();
    });
    this.connection.on("AgentOnlineStatus", (connectionId: string, status: boolean) => {
      const agentStatus = {
        connectionId: connectionId,
        status: status,
      };
      this.AgentConnectionIdList.push(connectionId);
      if (this.AgentConnectionIdList.count() > 0) {
        this.agentFound = true;
      } else {
        this.agentFound = false;
      }
    });
  }
  public Clear() {
    this.listMessage = [];
  }
}
