import { Router } from "@/helper/RouteModel";

const routes: Router[] = [
  {
    path: "/doctor",
    component: () => import("@/doctor-app/views/Layout.vue"),
    children: [
      {
        path: "",
        name: "list",
        component: () => import("@/doctor-app/views/doctor/List.vue") // unauthorized
      },
      {
        path: "doctor-list",
        name: "doctor-list",
        component: () => import("@/doctor-app/views/doctor/List.vue") // unauthorized
      },
      {
        path: "details/:id",
        name: "details",
        component: () => import("@/doctor-app/views/doctor/Details.vue") // unauthorized
      },
      {
        path: "dashboard",
        component: () => import("@/doctor-app/views/Dashboard.vue"), // authorized
        children: [
          {
            path: "",
            redirect: "", // default redirect to /dashboard
            component: () => import("@/doctor-app/views/home/Home.vue") // authorized
          },
          {
            path: "my-profile",
            name: "my-profile",
            component: () => import("@/doctor-app/views/my-profile/MyProfile.vue") // authorized
          },
          {
            path: "appointments",
            name: "appointments",
            component: () => import("@/doctor-app/views/appointment/List.vue") // authorized
          },
          {
            path: "appointment-calendar",
            name: "appointment-calendar",
            component: () => import("@/doctor-app/views/appointment/Calendar.vue") // authorized
          },
          {
            path: "chamber/:id",
            name: "chamber",
            component: () => import("@/doctor-app/views/chamber/Chamber.vue") // authorized
          },
          {
            path: "chamber-settings",
            name: "chamber-settings",
            component: () => import("@/doctor-app/views/chamber/Setting.vue") // authorized
          },
          {
            path: "no-chamber",
            name: "chamber-settings-0",
            component: () => import("@/doctor-app/views/chamber/NoChamber.vue") // authorized
          },
          {
            path: "chamber-create",
            name: "chamber-create",
            component: () => import("@/doctor-app/views/chamber/Create.vue") // authorized
          },
          {
            path: "vacation",
            name: "vacation",
            component: () => import("@/doctor-app/views/vacation/List.vue") // authorized
          },
          {
            path: "favourite",
            name: "favourite",
            component: () => import("@/doctor-app/views/favourite/List.vue") // authorized
          }
        ]
      }
    ]
  }
];

export default routes;
