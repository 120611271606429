import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/js/bootstrap.js";
import "@/assets/icons/style.css";
import VeeValidate from "vee-validate";
import bFormSlider from "vue-bootstrap-slider";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
// import "three-dots/dist/three-dots.min.css";
import StarRating from "vue-star-rating";
import "./filter-pipe";
import "./custom-vee-validation";

import VueMoment from "vue-moment";
import Datepicker from "vuejs-datepicker";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

Vue.use(VeeValidate);
Vue.use(bFormSlider);

Vue.component("star-rating", StarRating);
Vue.use(VueMoment);
Vue.component("datepicker", Datepicker);
Vue.use(BootstrapVue);

Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
Vue.component("cropper", VueCropper);

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
