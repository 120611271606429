import { Router } from "@/helper/RouteModel";
const Login = () => import("@/website/views/auth/Login.vue");
const Registration = () => import("@/website/views/auth/Registration.vue");
const Confirmation = () =>
  import("@/website/views/auth/RegistrationConfirmation.vue");
const Verify = () => import("@/website/views/auth/Verify.vue");
const ForgotPassword = () => import("@/website/views/auth/ForgotPassword.vue");

const routes: Router[] = [
  {
    path: "/",
    component: () => import("@/website/views/WebsiteLayout.vue"),
    children: [
      {
        path: "",
        name: "website",
        component: () => import("@/website/views/home/home.vue")
      },
      {
        path: "home",
        name: "home",
        component: () => import("@/website/views/home/home.vue")
      },
      {
        path: "how-it-works",
        name: "How It Works",
        component: () => import("@/website/views/home/how-it-works.vue")
      },
      {
        path: "about-us",
        name: "About Us",
        component: () => import("@/website/views/home/about-us.vue")
      },
      {
        path: "privacy-policy",
        name: "Prvacy Policy",
        component: () => import("@/website/views/home/privacy-policy.vue")
      },
      {
        path: "terms-conditions",
        name: "Terms and Conditions",
        component: () => import("@/website/views/home/terms-conditions.vue")
      },
      {
        path: "login",
        name: "Login",
        component: Login
      },
      {
        path: "registration",
        name: "Registration",
        component: Registration
      },
      {
        path: "registration-verify",
        name: "verify",
        component: Verify
      },
      {
        path: "registration-confirmation",
        name: "confirmation",
        component: Confirmation
      },
      {
        path: "forgot-password",
        name: "Forgot Password",
        component: ForgotPassword
      }
    ]
  }
];

export default routes;
