import { AuthAxios, UnAuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";

export default {
  ChatRating(data: any) {
    return UnAuthAxios.post(ApiURIs.ChatRating, JSON.stringify(data));
  },

  test() {
    return AuthAxios.get("api/user/test");
  }
};
