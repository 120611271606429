import { Router } from "@/helper/RouteModel";

const routes: Router[] = [
  {
    path: "/",
    component: () => import("@/common-app/views/MainLayout.vue"),
    children: [
      {
        path: "user",
        name: "User Dashboard",
        component: () => import("@/common-app/views/home/home.vue")
      },
      {
        path: "test",
        name: "Test",
        component: () => import("@/common-app/views/home/test.vue")
      },
      {
        path: "notification",
        name: "Notification",
        component: () => import("@/common-app/views/Notifications/notification.vue")
      }
    ]
  }
];

export default routes;
