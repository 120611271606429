import { GetterTree, MutationTree, ActionTree, ActionContext } from "vuex";
import { State } from "./State";
import { IDoctorChamber } from "../models/DoctorChamber";

type UserGetter = GetterTree<State, any>;

const state: State = {
  chambers: [],
  rating: 0,
  favourite: 0
} as State;

const getters: UserGetter = {
  chamberList: state => state.chambers,
  rating: state => state.rating,
  favourite: state => state.favourite
};

const mutations: MutationTree<State> = {
  addChamber(state, chamber: IDoctorChamber) {
    state.chambers.push(chamber);
  },
  addChamberList(state, chambers: IDoctorChamber[]) {
    state.chambers = chambers;
  },
  UpdateChamberList(state, chamber: IDoctorChamber) {
    let index = state.chambers.findIndex(s => s.id == chamber.id);
    //state.chambers[index] = chamber;
    state.chambers.splice(index, 1);
    state.chambers.push(chamber);
  },
  RemoveChamber(state, index: number) {
    state.chambers.splice(index, 1);
  },
  AddRating(state, rating: number) {
    state.rating = rating;
  },
  AddFavourite(state, fav: number) {
    state.favourite = fav;
  }
};

const actions: ActionTree<State, any> = {
  addChamber(store: ActionContext<State, any>, chamber: IDoctorChamber) {
    store.commit("addChamber", chamber);
  },
  addChamberList(store: ActionContext<State, any>, chambers: IDoctorChamber[]) {
    store.commit("addChamberList", chambers);
  },
  UpdateChamberList(store: ActionContext<State, any>, chamber: IDoctorChamber) {
    store.commit("UpdateChamberList", chamber);
  },
  RemoveChamber(store: ActionContext<State, any>, index: number) {
    store.commit("RemoveChamber", index);
  },
  AddRating(store: ActionContext<State, any>, rating: number) {
    store.commit("AddRating", rating);
  },
  AddFavourite(store: ActionContext<State, any>, fav: number) {
    store.commit("AddFavourite", fav);
  }
};

export const doctorModule = {
  state,
  getters,
  mutations,
  actions
};
